import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;
    background-color:#111;
    margin: -25% 0 0 0;
    h1{
        text-align: left;
        margin-bottom: 1rem;
    }

    ul{
        list-style: none;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        column-gap: 1rem;
        row-gap: 1rem;
    }

    li{
        display: flex;
        flex-direction: column;
        align-items:center;
    }

    img{
        width: 250px;
        border-radius: 0.2rem;
        margin-bottom: 0.3rem;
    }

    span{
        font-weight: bold;
        font-size: 100%;
        text-align: center;
    }

    p{
        font-weight:bold;
        font-size: 70%;
        opacity: 50%;
    }

    a{
        transition: all 0.2s;
    }

    a:hover{
        transform: scale(1.1);
    }


`
