import { useParams } from "react-router-dom";
import { APIKey } from "../../config/key";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Movie } from "../Details/styles";
import Header from "../../components/header";
import Footer from "../../components/footer";


function Details(){

    const [details, setDetails] = useState({})

    const { id } = useParams()

    const path = `https://api.themoviedb.org/3/movie/${id}?api_key=${APIKey}&language=pt-BR&region=BR`;
    const image_path = 'https://image.tmdb.org/t/p/w500'
    const back_path = 'https://image.tmdb.org/t/p/original'

    useEffect(() => {
        fetch(path)
            .then(response => response.json())
            .then(data => {
                setDetails(data) 
                console.log(data)
            })
    }, [id])
    
    
    return(
        <div>
            <Header />
            <Container>
            <Movie>
                <img src={image_path+details.poster_path} />
                <div className='textos'>
                <h1>Detalhes do Filme</h1>
                    <h2><b>Título:</b> {details.title} </h2>
                    <p><b>Sinopse:</b> {details.overview}</p>
                    <p><b>Lançamento:</b> {details.release_date}</p>
                    <p><b>Tempo:</b> {details.runtime} min </p>
                    <p><b>Status:</b> {details.status} </p>
                    <p><b>Popularidade:</b> {details.popularity}</p>
                    <p><b>Nota:</b> {details.vote_average}</p>
                    <p><b>Votos:</b> {details.vote_count}</p>
                    <p><b>Título original:</b> {details.original_title}</p>
                    <p><b>Idioma Original:</b> {details.original_language}</p>
                    <p><b>Orçamento:</b> $ {details.budget}</p>
                    <p><b>Arrecadação:</b> $ {details.revenue}</p>
                    <Link to='/'>
                        <button>Voltar</button>
                    </Link>
                    

                </div>
            </Movie>
            <img src={back_path+details.backdrop_path} />
        </Container>
        <Footer />
        </div>
    )
}

export default Details;