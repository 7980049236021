import styled from "styled-components";

export const Container = styled.div`

    img{
        width: 100%;
        margin:0;
    }

`
export const Movie = styled.div`

    padding: 1rem 1rem 3rem 1rem;
    
    display:flex;
    align-items:center;
    justify-items: center;
    

    h1{
        text-align: left;
        margin:1rem 0;
    }

    .textos{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 1rem;
        max-width: 60%;

    }

    img{
        width: 250px;
        border-radius: 0.2rem;
        margin-bottom: 0.3rem;
    }

    span{
        font-weight: bold;
        font-size: 100%;
        text-align: center;
    }

    p{
        font-size: 100%;
        line-height:130%
    
    }

    a{
        transition: all 0.2s;
    }

    a:hover{
        transform: scale(1.1);
    }

    button{
        background: white;
        border: none;
        cursor: point;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        font-size: 100%;
    }

    button:hover{
        background-color: #dddddd;

    }

`
