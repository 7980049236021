import { Cabecalho } from "./styles";

function Header(){
    return(
        <Cabecalho>
            <img className='logo' src='../assets/logo.png' />
            <h1>Assistidor</h1>
            <img src='../assets/assistidor_header.png' />
        </Cabecalho>
    )
}

export default Header;