import styled from "styled-components";

export const Cabecalho = styled.div`
    margin: 0;
    padding: 1rem 0;
    background-color:black;

    h1{
        text-size:150%;
        padding: 0 1rem;
        display: inline-block;
        margin-bottom:1rem;
    }

    img{
        width:100%;
    }

    .logo{
        width: 5%;
        display: inline-block;
        
    }
`

export const Rodape = styled.div`
    margin: 0;
    padding:3rem;
    text-align:center;
    background-color:black;

    h1{
        text-size:150%;
    }

`