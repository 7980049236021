import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { APIKey } from "../../config/key";
import { Container } from "./styles";


function Home(){

    const [movies, setMovies] = useState([])

    const path = `https://api.themoviedb.org/3/movie/popular?api_key=${APIKey}&language=pt-BR&region=BR&page=1`;
    const image_path = 'https://image.tmdb.org/t/p/w500'

    useEffect(() => {
        fetch(path)
            .then(response => response.json())
            .then(data => {
                setMovies(data.results) 
                console.log(data.results)
            })
    }, [])

    return(
        <div>
            <Header />
            <Container>
                <h1>Mais Populares</h1>
                <ul>
                    {movies.map(movie => {
                        return(
                            <li key={movie.id}>
                                <Link to={`/details/${movie.id}`}>
                                    <img src={image_path+movie.poster_path} />
                                </Link>
                                <span>
                                    {movie.title}
                                </span>
                                <p>
                                    {movie.release_date.substring(0, 4)}
                                </p>
                            </li>
                        )
                    })}
                
                </ul>
            </Container>
            <Footer />
        </div>
    
    )
}

export default Home;