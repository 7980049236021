import { Rodape } from "./styles";

function Footer(){
    return(
        <Rodape>
            <h1>Assistidor</h1>
            <p>2023 © Todos os direitos reservados.</p>
        </Rodape>
    )
}

export default Footer;